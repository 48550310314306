import React, { useEffect } from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyDrStaciTemplate = ({ image }) => {
  useEffect(() => {
    const script1 = document.createElement("script")
    const script2 = document.createElement("script")

    script1.src = "https://fast.wistia.com/embed/medias/l4eb1wk31e.jsonp"
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js"
    document.body.appendChild(script1)
    document.body.appendChild(script2)
  }, [])
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Doctor Staci"
        description="Can we make dentistry exciting at scale?"
        image={image}
      />
      <CaseStudyDescriptionSection
        title={
          <h3>
            Recently, Dr. Staci, a pediatric dentist based in Portland, Oregon,
            approached us with an interesting problem:
            <br />
            <br />
            Though she was a successful dentist, she was struggling to bring her
            work of treating and preventing children’s cavities to a global
            audience. People couldn’t look past “filling cavities” to understand
            the full impact she was having on her clients through her innovative
            dental techniques.
            <br />
            <br />
            Throughout our initial conversation with Dr. Staci, we were blown
            away by some of the research and statistics around her work. We,
            like most of you, had no idea that cavities were the number one
            chronic disease in children in the world, and that 9 out of 10 kids
            develop sleep and/or breathing issues because of cavities.
            <br />
            <br />
            <span>
              So how did we take Dr. Staci from fighting cavities to a top
              thought leader known for saving children’s lives globally?
            </span>
            <br />
            <br />
            During our Hook Point sessions, we realized that the problem wasn’t
            with Dr. Staci’s content – the educational materials she had were
            top-notch. The main issue preventing Dr. Staci from scaling was that
            cavities just aren’t an inherently captivating topic for the vast
            majority of people.
            <br /> <br /> So…how did we get people from skipping past her
            content, which was all about cavities? <br />
            <br />
            Our first Hook Point was to reposition Dr. Staci’s mission
            statement. She wasn’t just a dentist talking about children’s
            cavities, but an expert on chronic disease in children. Cavities may
            be a dry subject, and therefore easy to ignore, but when you’re
            talking about a silent epidemic that’s affecting kids around the
            world, it suddenly becomes a lot harder to scroll past. In other
            words,
            <span>
              {" "}
              most parents want to do whatever they can to prevent their kids
              from suffering from a chronic disease, where they might not have
              given their kids’ cavities a second thought.
            </span>
            <br />
            <br />
            We also helped Dr. Staci with the following:
            <br />
            <br />
          </h3>
        }
        list={
          <ul>
            <li>
              Restructured her compostable floss pick business, as part of a
              subscription box “Hygiene Kit”. This gave her a sustainable,
              scalable source of revenue.
            </li>
            <li>
              Developed a strategy that allowed Dr. Staci to connect with
              influencers in the health and wellness space, as well as with
              private schools and environmental organizations, providing her a
              bigger platform and the ability to expand her professional
              network.
            </li>
            <li>
              Crafted a content strategy that allowed Dr. Staci to address case
              studies around children’s oral health with dramatizations of real
              stories she’s experienced treating patients.
            </li>
            <li>
              Through our communication analysis, we found that Dr. Staci was
              communicating with only a small percentage of the population (a
              common trend with clients who have not previously implemented our
              communication algorithm). She added our communication strategy to
              her repertoire, and now she is effectively connecting and engaging
              at scale.
            </li>
          </ul>
        }
        moreInfo={
          <h3>
            So how did it work out for her? Well, Dr. Staci will soon be{" "}
            <span>
              hiring a new dentist at her practice because people are driving
              from hours away just to see her.{" "}
            </span>
            In only a few short months, her schedule is more packed now than it
            has ever been. She’s also started working on her new book after
            <span> we helped land her a publishing deal</span>, and she is also
            bringing her new patented flosser to the market. We’re incredibly
            proud of how far she’s come, and we’re confident her profile is only
            going to continue to grow.
            <br />
            <br />
            Video testimonial:
            <br />
            <br />
            <br />
            {/* //#region */}
            <div
              style={{
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                paddingBottom: "100px",
              }}
            >
              <script
                src="https://fast.wistia.com/embed/medias/l4eb1wk31e.jsonp"
                async
              ></script>
              <script
                src="https://fast.wistia.com/assets/external/E-v1.js"
                async
              ></script>
              <span
                class="wistia_embed wistia_async_l4eb1wk31e popover=true popoverAnimateThumbnail=true"
                style={{
                  display: "inline-block",
                  height: "640px",
                  position: "relative",
                  width: "360px",
                  zIndex: 9999,
                }}
              >
                &nbsp;
              </span>
            </div>
            {/*//#regionend */}
          </h3>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-tylor-swift"}
        text="We’ve got more to tell about our work with Craig. "
        subText=" Let’s talk"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyDrStaciTemplate
